import React from 'react';
import Aux from '../../hoc/Auxs'
import Toolbar from '../Navigation/Toolbar/Toolbar'
import Sidebar from '../Navigation/Sidebar/Sidebar'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux'


const layout = (props) => (
    <Aux>
        <Toolbar />
        <Container as="div" fluid style={{ marginTop: 5 }} >
            <Row>
                <Col lg={2} md={2} className="d-none d-lg-block" >
                    <Sidebar />
                </Col>
                <Col lg={10} md={10}>
                    {props.children}
                </Col>
            </Row>
        </Container>
        {/* <main>
            
        </main> */}
    </Aux>
)

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    }
}
export default connect(mapStateToProps, null)(layout);