import React, { Component } from 'react'
import { withRouter } from 'react-router'
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

import { connect } from 'react-redux'
import * as actions from '../../../store/actions/';
import Auth from '../../../containers/Auth/Auth'


class ProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalShow: false,
        }
    }

    render() {
        let productJsxData = null;
        if (this.props.product) {
            let product = this.props.product;
            productJsxData = (
                <Card>
                    <div style={{ height: 200, textAlign: 'center', alignContent: "center" }}>
                        <img style={{
                            // keep this as it is
                            overflow: 'auto',
                            height: "100%",
                            maxWidth: "100%"
                        }}
                            src={process.env.REACT_APP_HOST + '/storage/' + product.image}
                            onClick={() => this.setState({ ...this.state, modalShow: true })}
                            alt=""
                        />
                    </div>
                    <Card.Body>
                        <h5 onClick={() => this.setState({ ...this.state, modalShow: true })} style={{ fontSize: "18px !important" }}>{product.name}</h5>

                        {product?.prices ? (
                            <>
                                <Card.Title> Your buying price {' '}<Badge variant="success" className="float-right" >4.5 <FontAwesomeIcon icon={faStar} /> </Badge></Card.Title>
                            </>
                        )
                            : null}
                    </Card.Body>
                </Card>
            )
        }

        let modal = null;
        if (!this.props.isAuthenticated) {
            modal = (<Auth show={this.state.modalShow} onHide={() => this.setState({ ...this.state, modalShow: false })} />)
        }

        return (
            <React.Fragment>
                {modal}
                {productJsxData}
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        token: state.auth.token,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onAddToCart: (cart) => dispatch(actions.addToCart(cart)),
        onAddUpdatePrice: (resellerPrice, id, token, productId) => dispatch(actions.addUpdatePrice(resellerPrice, id, token, productId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductList));