import React, { useState, useEffect, Suspense } from 'react';
import Layout from './components/Layout/Layout'
import { Route, Switch, withRouter, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux'
import * as actions from './store/actions/';

import Home from './containers/Home/Home'
const NewOrder = React.lazy(() => import('./containers/NewOrder/NewOrder'))
const Order = React.lazy(() => import('./containers/Order/Order'))
const MyInfo = React.lazy(() => import('./containers/MyInfo/MyInfo'))
const AddUser = React.lazy(() => import('./containers/Auth/Signup/AddUser'))
const UserList = React.lazy(() => import('./containers/UserList/UserList'))
const Balance = React.lazy(() => import('./containers/Balance/Balance'))
const TransferBalance = React.lazy(() => import('./containers/UserList/TransferBalance'))
const AddBalance = React.lazy(() => import('./containers/Balance/AddBalance'))
const Logout = React.lazy(() => import('./containers/Auth/Logout/Logout'))
const Register = React.lazy(() => import('./containers/Auth/Signup/Register'))

const App = (props) => {
  const [priviousToken, setPriviousToken] = useState(null);
  useEffect(() => {
    props.onTryAutoSignup();
    props.onSetCartOnReload();
  }, [])
  let location = useLocation()
  useEffect(() => {
    // console.log("priviousToken  props.isAuthenticated", priviousToken, props.isAuthenticated)
    // console.log("location == ", location.pathname)
    if (!priviousToken && props.token && location.pathname == "/") {
      props.history.push('/new-order');
    }
    setPriviousToken(props.token)
  }, [props.token])

  let routes = (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/register" render={() => (
        <Suspense fallback={<div>Loading...</div>} ><Register /></Suspense>
      )} />
      <Redirect to="/" />
    </Switch>
  )

  if (props.isAuthenticated) {
    routes = (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/new-order" render={() => (
          <Suspense fallback={<div>Loading...</div>} ><NewOrder /></Suspense>
        )} />
        <Route path="/order" render={() => (
          <Suspense fallback={<div>Loading...</div>} ><Order /></Suspense>
        )} />
        <Route path="/my-info" render={() => (
          <Suspense fallback={<div>Loading...</div>} ><MyInfo /></Suspense>
        )} />
        <Route path="/add-user" render={() => (
          <Suspense fallback={<div>Loading...</div>} ><AddUser /></Suspense>
        )} />
        <Route path="/user-list" render={() => (
          <Suspense fallback={<div>Loading...</div>} ><UserList /></Suspense>
        )} />
        <Route path="/balance" render={() => (
          <Suspense fallback={<div>Loading...</div>} ><Balance /></Suspense>
        )} />
        <Route path="/balance-transfer" render={() => (
          <Suspense fallback={<div>Loading...</div>} ><TransferBalance /></Suspense>
        )} />
        <Route path="/add-balance" render={() => (
          <Suspense fallback={<div>Loading...</div>} ><AddBalance /></Suspense>
        )} />
        <Route path="/logout" render={() => (
          <Suspense fallback={<div>Loading...</div>} ><Logout /></Suspense>
        )} />
      </Switch>
    )

  }


  return (
    <div>
      <Layout>
        {routes}
      </Layout>
    </div>
  );
}


const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    token: state.auth.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    onSetCartOnReload: () => dispatch(actions.cartReload())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
