import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router'
import ListGroup from 'react-bootstrap/ListGroup';
import { connect } from 'react-redux'

import Auth from '../../../containers/Auth/Auth'

import * as actions from '../../../store/actions/';
import Aux from '../../../hoc/Auxs'

const Sidebar = (props) => {
    const [modalShow, setModalShow] = useState(false)

    useEffect(() => {
        props.onInitCategories();
    }, [])

    const redirectTo = (link) => {
        // console.log("link ==", link)
        if (props.closeNav)
            props.closeNav()
        props.history.push('/' + link);
    }

    const sidebars = [
        { name: "New Order", path: "new-order" },
        { name: "Order", path: "order" },
        { name: "My Info", path: "my-info" },
        { name: "Add User", path: "add-user" },
        { name: "My User", path: "user-list" },
        { name: "Balance", path: "balance" },
        { name: "Transfer Balance", path: "balance-transfer" },
        { name: "Add Balance", path: "add-balance" },
        { name: "Logout", path: "logout" }
    ]


    let sidebarList = null;
    if (!props.isAuthenticated) {
        if (props.cats) {
            sidebarList = (
                <Aux>
                    {props.cats.map(cat => {
                        return (<Link to="#" onClick={() => setModalShow(true)} key={cat.id} >
                            <ListGroup.Item  ><strong>{cat.name}</strong></ListGroup.Item>
                        </Link>)
                    })}
                </Aux>
            )
        }
    } else {
        sidebarList = (
            <Aux>
                {sidebars.map(sidebar => {
                    return (<Link to="#" onClick={() => redirectTo(`${sidebar.path}`)} key={sidebar.path} >
                        <ListGroup.Item  ><strong>{sidebar.name}</strong></ListGroup.Item>
                    </Link>)
                })}
            </Aux>
        )
    }

    let modal = null;
    if (!props.isAuthenticated) {
        modal = (<Auth show={modalShow} onHide={() => setModalShow(false)} />)
    }
    return (<>
        {modal}
        <ListGroup>
            {sidebarList}
        </ListGroup>
    </>)

}


const mapStateToProps = state => {
    return {
        cats: state.category.categories,
        isAuthenticated: state.auth.token !== null
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitCategories: () => dispatch(actions.initCategories()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));